import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  Impressum: "/nl/impressum"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "de",
    href: "/de/impressum"
  },
  {
    hreflang: "en",
    href: "/en/imprint"
  },
  {
    hreflang: "nl",
    href: "/nl/impressum"
  },
  {
    hreflang: "se",
    href: "/se/juridisk-information"
  },
  {
    hreflang: "da",
    href: "/da/juridisk-information"
  },
  {
    hreflang: "no",
    href: "/no/imprint"
  },
  {
    hreflang: "x-default",
    href: "/en/imprint"
  }
];

const Imprint = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Impressum"
        description="Informatie over de beheerder van de website, evenals juridische kennisgevingen."
        lang="nl"
        alternateLangs={alternateLangs}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <MainContent article={false}>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Impressum</H>
        <p>Beheerder van de website bluerivermountains.com:</p>
        <p>
          Matthias Kupperschmidt
          <br />
          Frederikssundsvej 22
          <br />
          2400 Kopenhagen
          <br />
          Denemarken
        </p>
        <p className="baseline">
          Email: <a href="mailto:info@bluerivermountains.com">info@bluerivermountains.com</a>
          <br />
          Telefoon: +45 93 86 85 64
        </p>
        <p className="baseline">BTW-identificatienummer: 39474263</p>
        <p>Deze site is auteursrechtelijk beschermd door Matthias Kupperschmidt.</p>
        <H as="h2">Juridisch</H>
        <p>
          Ik controleer regelmatig de informatie op deze website. Ondanks alle zorgvuldigheid kunnen de gegevens in de
          tussentijd zijn gewijzigd. Daarom kan geen aansprakelijkheid of garantie voor de actualiteit, juistheid en
          volledigheid van de informatie worden aanvaard. Hetzelfde geldt voor websites waarnaar wordt verwezen via
          hyperlinks. Matthias Kupperschmidt is niet verantwoordelijk voor de inhoud van de websites die worden bereikt
          als gevolg van een dergelijke verbinding. Reproductie van informatie of gegevens – vooral het gebruik van
          teksten, tekstgedeelten of afbeeldingen – vereist voorafgaande toestemming. Dit geldt ook voor alle
          blogberichten. Ik wijs erop dat ik alle gegevens die in het zoekmasker worden ingevoerd opsla en deze gegevens
          niet gebruik voor eigen reclame, noch doorgeef aan derden.
        </p>
        <p>
          Verdere informatie is te vinden in mijn <Link to="/nl/privacybeleid">privacybeleid</Link>.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default Imprint;
